import BaseModel from './BaseModel';
import { DataConverter as LeadDataConverter } from '@/FirestoreDataConverters/Lead.js';

export default class Lead extends BaseModel {
  constructor(options) {
    super(options);
  }

  all() { //get all data of zone
    return this.db.collection('leads').withConverter(LeadDataConverter);
  }
}