<template>
  <div style="position:relative">
    <portal to="title-page">
      <h1>Propespectos de las landings</h1>
    </portal>
    <v-data-table
      v-if="leads != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="leads"
      :search="search"
      item-key="id"
      fixed-header
      class="elevation-1"
      show-expand
      :custom-filter="filterCupones"  
    >
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify "
                label="Search"
                color="#f88511"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" class="offset-md-2" md="6" sm="12">
              <router-link :to="{ path: 'cupones/create' }">
                <v-btn dark small color="#f88511">
                  <v-icon>mdi-plus-thick</v-icon>Cupon
                </v-btn>
              </router-link>
            </v-col> -->
          </v-row>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card
          >
            <div class="d-flex">
              <div>
                <v-img
                  class="white--text align-end"
                  width="300px"
                  :src="item.landingImg"
                >
                  <v-card-title>{{item.landingTitle}}</v-card-title>
                </v-img>
              </div>
              <div>

                <v-card-subtitle class="pt-1 pb-0">
                  Landing : <strong>{{item.landingTitle}}</strong>
                </v-card-subtitle>
                <v-card-subtitle class="pt-1 pb-0">
                  Nombre completo : <strong>{{item.fullName}}</strong>
                </v-card-subtitle>
                <v-card-subtitle class="pt-1 pb-0">
                  Email : <strong>{{item.email}}</strong>
                </v-card-subtitle>
                <v-card-subtitle class="pt-1 pb-0">
                  Tel : <strong>{{item.phone}}</strong>
                </v-card-subtitle>
                <v-card-subtitle class="pt-1 pb-0">
                  Fecha de registro : <strong>{{$moment.unix(item.registerDate.seconds).format('YYYY-MM-DD HH:mm')}}</strong>
                </v-card-subtitle>

                <v-card-text class="text--primary my-4">
                  <a target="_blank" :href="getApiUrl()+item.landingUrl">Visitar landing</a>
                </v-card-text>

                <!-- <v-card-actions>
                  <a target="_blank" :href="item.landingUrl">Visitar landing</a>
                </v-card-actions> -->
              </div>
            </div>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">Cerrar</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Lead from "@/models/Lead.js";
import { mdiPencil, mdiImageMultiple } from "@mdi/js";
import firebase from 'firebase/app';
import lodash from 'lodash';
import 'firebase/firestore';


export default {
  name: "AdminLeads",
  data() {
    return {
      /***  iconos  ****/
      mdiImageMultiple:mdiImageMultiple,
      mdiPencil: mdiPencil,
      model: new Lead(),
      // moment: new moment(),
      /**** table data ******/
      date : firebase.firestore.Timestamp.fromDate(new Date),
      leads: null,
      selected: [],
      search: "",
      tableHeaders: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "fullName",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Tel.",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Landing",
          align: "start",
          sortable: true,
          value: "landingTitle",
        },
      ],
      /******  Mensajes  *******/
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
    };
  },
  methods: {
    formatDate(value){
      //let date = value.seconds || false;
      if(lodash.has(value, 'seconds')){
        return this.$moment(value.toDate()).format('DD-MM-YYYY');
        // return this.$moment(String(value)).format('DD-MM-YYYY');

      }
    },
    filterCupones(value, search) {
      if (value != null && search != null) {
        if (value.seconds) {
          return this.$moment(value.toDate()).format('DD-MM-YYYY').indexOf(search) !== -1;
        }
        return typeof value === 'string' && value.indexOf(search) !== -1
      }
    },
    getApiUrl() {
      return process.env.VUE_APP_API_URL
    }
  },
  mounted() {
    this.model.all().onSnapshot((qsnap) => {
      this.leads = [];
      qsnap.forEach((doc) => {
        this.leads.push(doc.data())
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
<style>
a {
  text-decoration: none;
  /* quita el subrayado de routerlink */
}
.table-top-bar {
  text-align: right;
  padding: 16px 16px 0px 16px;
}
</style>