class Lead {
  id;
  constructor(data) {
    this.id = data.id
    this.acceptEmails = data.acceptEmails || false
    this.fullName = data.fullName
    this.email = data.email
    this.grantPermission = data.grantPermission || false;
    this.landing = data.landing || ''
    this.landingId = data.landingId || ''
    this.landingImg = data.landingImg || ''
    this.landingTitle = data.landingTitle || ''
    this.landingUrl = data.landingUrl || ''
    this.lang = data.lang || ''
    this.phone = data.phone || ''
    this.registerDate = data.registerDate || ''
  }
}
var DataConverter = {
  toFirestore: function(lead) {
    return {
      acceptEmails: lead.acceptEmails,
      fullName: lead.fullName,
      email: lead.email,
      grantPermission: lead.grantPermission,
      landing: lead.landing,
      landingId: lead.landingId,
      landingImg: lead.landingImg,
      landingTitle: lead.landingTitle,
      landingUrl: lead.landingUrl,
      lang: lead.lang,
      phone: lead.phone,
      registerDate: lead.registerDate,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Lead(data)
  }
}
export {
  Lead,
  DataConverter
}